import { useEffect } from 'react'
import PageLoader from '@/components/ui/PageLoader'
import router from 'next/router'

interface IProps {
  to: string
  showLoaderSplash?: boolean
}

export default function Redirect({ to, showLoaderSplash = true }: IProps) {
  useEffect(() => {
    router.replace(to)
  }, [to])

  return showLoaderSplash ? <PageLoader /> : null
}
