import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import useAuth from '@/hooks/useAuth'
import Redirect from '@/components/ui/Redirect'
import PageLoader from '@/components/ui/PageLoader'

const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
}

export default function requireNoAuth<P>(Component: React.ComponentType<P>) {
  return (props: P) => {
    const [showSplash, setShowSplash] = useState(true)
    const auth = useAuth()

    useEffect(() => {
      auth.startPolling(500)
      return () => auth.stopPolling()
    }, [auth?.startPolling, auth?.stopPolling])

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowSplash(false)
        auth.stopPolling()
      }, 3000)
      return () => clearTimeout(timer)
    }, [auth?.stopPolling])

    if (auth?.loggedIn) {
      return <Redirect to='/global' />
    }

    return (
      <AnimatePresence exitBeforeEnter initial={false}>
        {showSplash ? (
          <motion.div
            key='page-loader'
            variants={variants}
            initial='hidden'
            animate='enter'
            exit='exit'
            transition={{ type: 'linear' }}
          >
            <PageLoader />
          </motion.div>
        ) : (
          <motion.div
            key='children'
            variants={variants}
            initial='hidden'
            animate='enter'
            exit='exit'
            transition={{ type: 'linear' }}
          >
            <Component {...props} />
          </motion.div>
        )}
      </AnimatePresence>
    )
  }
}
