import { Box, Flex, Spinner } from '@chakra-ui/react'
import Image from 'next/image'

const LOGO_ASPECT_RATIO = 406 / 364

// opt-out of image optimization, no-op
const customLoader = ({ src }) => {
  return src
}

export default function PageLoader() {
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      h='100vh'
      w='full'
      bgColor='#E9ECF2'
    >
      <Flex flexDir='column' alignItems='center'>
        <Box mb='8'>
          <Image
            src='/LogoLight.png'
            alt='HiveWatch Logo'
            width={230}
            height={230 / LOGO_ASPECT_RATIO}
            quality={4}
            loader={customLoader}
          />
        </Box>
        <Spinner
          thickness='5px'
          speed='0.65s'
          emptyColor='gray.300'
          color='#3D7FCE'
          size='xl'
        />
      </Flex>
    </Flex>
  )
}
