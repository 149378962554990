import requireNoAuth from '@/utils/auth/requireNoAuth'
import useAuth from '@/hooks/useAuth'
import PageLoader from '@/components/ui/PageLoader'
import { useEffect } from 'react'
import { mixpanel } from '@/utils/analytics'

function Landing() {
  const auth = useAuth()

  useEffect(() => {
    mixpanel.track('Page View: LoginForm')
    auth?.logIn()
  }, [])

  return <PageLoader />
}
const LandingPage = requireNoAuth(Landing)

export default LandingPage
